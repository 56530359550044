import React, { useState } from "react"
import { css } from "@emotion/core"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import { CardWrapper } from "../components/UI/Card"
import InternalLink from "../components/UI/InternalLink"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import ColumnTitle from "components/UI/ColumnTitle"
import List from "components/UI/List"
import linkResolver from "utils/linkResolver"
import sort from "utils/sort"
import LightBox from "components/UI/LightBox"
import InputBox from "components/UI/InputBox"
import TextBox from "components/UI/TextBox"
import t from "theme"
import Button from "components/UI/Button"
import RichText from "components/UI/RichText"

//uses GraphQL back reference - which automatically links articles back to company
export const query = graphql`
    query($ticker: String!) {
        selectedCompany: contentfulCompany(ticker: { eq: $ticker }) {
            ...CompanyFields
            question {
                ...QuestionContent
            }
        }
    }
`

const QuestionArchive = ({ pageContext: { commonData, ticker }, data: { selectedCompany }, location }) => {
    const featuredArticles = getFeaturedArticles(commonData, ticker)
    const questions = selectedCompany.question

    return (
        <MainLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature={`Q&A`}
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title={"FEATURED ARTICLES"} featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={selectedCompany.shortName + " Q&A"}
                pageDescription={"Q&A on " + selectedCompany.shortName}
                pagePathname={location.pathname}
            />

            <div>
                <ColumnTitle>{"LATEST Q&A ON " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>
                <LightBox
                    toggleComponent={
                        <Button
                            css={css`
                                width: 100%;
                                font-size: ${t.font.size[6]};
                                margin-bottom: ${t.space[7]};
                            `}>
                            ASK A QUESTION
                        </Button>
                    }>
                    <ColumnTitle>ASK US A QUESTION</ColumnTitle>
                    <form
                        name="QUESTION"
                        method="POST"
                        netlify-honeypot="bot-field"
                        data-netlify="true"
                        action="/success">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="QUESTION" />
                        <InputBox
                            css={css`
                                max-width: 100%;
                                width: 100%;
                                margin-bottom: ${t.space[2]};
                            `}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="E-MAIL ADDRESS"
                        />
                        <TextBox
                            css={css`
                                width: 100%;
                                height: 40vh;
                            `}
                            name="q-body"
                            id="q-body"
                            placeholder="TYPE YOUR QUESTION"></TextBox>
                        <Button
                            css={css`
                                width: 100%;
                                font-size: ${t.font.size[6]};
                            `}
                            type="submit">
                            SUBMIT QUESTION
                        </Button>
                    </form>
                </LightBox>

                <List
                    items={questions.sort(sort("sortTimestamp", "desc")).map((question, index) => (
                        <CardWrapper shadow>
                            <InternalLink
                                headline={question.headline}
                                date={question.sortTimestamp}
                                pathname={linkResolver({ slug: question.slug, type: "question" })}
                                preview={
                                    <RichText
                                        body={question.question.json}
                                        linkUrl={linkResolver({ slug: question.slug, type: "question" })}
                                    />
                                }
                            />
                        </CardWrapper>
                    ))}
                    space={t.space[5]}
                    nodivider
                />
            </div>
        </MainLayout>
    )
}

export default QuestionArchive
