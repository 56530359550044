import styled from "@emotion/styled"
import { css } from "@emotion/core"
import t from "theme"

const TextBox = styled.textarea`
    border: 1px solid ${t.color.subtlegrey};
    padding: ${t.space[2]};
    border-radius: 2px;
    font-family: "Open Sans";
    color: ${t.color.regtext};
    font-size: ${t.font.size[2]};
    &::placeholder {
        color: ${t.color.lighttext};
    }
`

export default TextBox
